
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
    //font-family: 'Roboto', sans-serif;
    font-family: 'Roboto Mono', monospace;
    font-weight: 100;
    margin-top: 30px;
    margin-bottom: 50px;
}

.heading-1 {
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Roboto Mono', monospace;
}